import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/service/api.service";
import * as M from "../../../../node_modules/materialize-css";

@Component({
  selector: "app-lifecycle-mobile",
  templateUrl: "./lifecycle-mobile.component.html",
  styleUrls: ["./lifecycle-mobile.component.css"],
})
export class LifecycleMobileComponent implements OnInit {
  constructor(private api: ApiService) {}

  public item;
  public data;
  public messages;
  ngOnInit(): void {
    setTimeout(function () {
      var elems = document.querySelectorAll(".collapsible");
      var instances = M.Collapsible.init(elems);
    }, 0);

    this.data = JSON.parse(sessionStorage.getItem("allData"));
  }
  ngAfterViewInit() {
    //this.headlessCMS();
  }

  processes = [
    {
      title: "Harvest",
      position: 0,
      id: "harvest",
      api: "Harvest",
      class: "active",
      message: "",
    },
    {
      title: "Action",
      position: 1,
      id: "action",
      api: "",
      class: "",
      message: "",
    },
    {
      title: "Sales",
      position: 2,
      id: "sales",
      api: "Sales",
      class: "",
      message: "",
    },
    {
      title: "Transport",
      position: 3,
      id: "transport",
      api: "Logistics",
      class: "",
      message: "",
    },
    {
      title: "Roasting",
      position: 4,
      id: "roasting",
      api: "Roasting",
      class: "",
      message: "",
    },
    {
      title: "Training",
      position: 5,
      id: "training",
      api: "",
      class: "",
      message: "",
    },
    {
      title: "Grading",
      position: 6,
      id: "grading",
      api: "Grading",
      class: "",
      message: "",
    },
    {
      title: "Washing",
      position: 7,
      id: "washing",
      api: "Washing",
      class: "",
      message: "",
    },
  ];
  // to implement the HeadlessCMS
  headlessCMS() {
    this.api.getHeadlessCMS().subscribe((res) => {
      //subscribing to the headless cms
      this.messages = res; //putting the result into a variable

      Object.keys(this.messages).forEach((key) => {
        //looping through the object
        Object.keys(this.processes).forEach((key2) => {
          //looping again
          if (this.messages[key].title.rendered == this.processes[key2].title) {
            //finding matches between the 2 loops

            this.processes[key2].message = this.messages[key].content.rendered; //putting the message in the process object
            this.processes[key2].message = this.processes[key2].message.replace(
              /<{1}[^<>]{1,}>{1}/g,
              " "
            ); //removing the html tags
          }
        });
      });
    });
  }
}
