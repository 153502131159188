import { Component, OnInit } from "@angular/core";
import * as M from "../../../../node_modules/materialize-css";
import { Router } from "@angular/router";

@Component({
  selector: "app-timed-out",
  templateUrl: "./timed-out.component.html",
  styleUrls: ["./timed-out.component.css"],
})
export class TimedOutComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {
    // const elem = document.getElementById('modal');
    // const instance = M.Modal.init(elem, {dismissible: false});
    // instance.open();
  }
}
