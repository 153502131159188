import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css"],
})
export class SignInComponent implements OnInit {
  hero;
  errorMsg = "";
  user;
  loadercircle: boolean = false;
  target: any;
  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    public router: Router
  ) {
    this.hero = this.authService.getHeroImg();
    this.authService.SignOut();
  }

  ngOnInit() {
    this.target = this.route.snapshot.params["target"];
    this.route.params.subscribe((result) => {
      this.target = result["target"];
    });
  }
  //clear this.errorMsg
  resetError() {
    this.errorMsg = "";
  }

  //checks if there is a error.
  loader() {
    if (!this.errorMsg) {
      this.loadercircle = true;
    } else {
      this.loadercircle = false;
    }
  }
  //get error from auth services
  getError() {
    this.errorMsg = this.authService.getError();

    this.loader();
  }

  //signs user in
  signInMethod(email, password) {
    if (this.target == "sign-in") {
      this.authService
        .SignIn(email, password, "sign-in")
        .then((res) => this.getError());
    } else {
      this.authService
        .SignIn(email, password, this.target)
        .then((res) => this.getError());
    }
  }
}
