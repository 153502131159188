<app-navbar [parentUrl]="url"></app-navbar>

<div class="container row">
    <div class="coffeeButtons col s12 push-s2 push-l1">
        <div class="button col 12 m6 xl3">
            <a class="waves-effect waves-light btn" routerLink="kenyaTriple">Keyna Triple</a>
        </div>
        <div class="button col s12 m6 xl3">
            <a class="waves-effect waves-light btn" routerLink="kenyaSingle">Keyna Single</a>
        </div>
        <div class="button col s12 m6 xl3">
            <a class="waves-effect waves-light btn" routerLink="ethopia-double">Ethopia Double</a>
        </div>
        <div class="button col s12 m6 xl3">
            <a class="waves-effect waves-light btn" routerLink="single-2018">Single 2018</a>
        </div>
        <div class="button col s12 m6 xl3 offset-xl4">
            <a class="waves-effect waves-light btn" routerLink="ethopia-double-TL">Ethopia Double 2</a>
        </div>
    </div>
</div>