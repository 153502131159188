<app-navbar></app-navbar>

<div>
  <a data-target="slide-out" class="sidenav-trigger waves-light btn halfbtn">Status</a>
</div>
<div class="time-line row">

  <div class="row col s12 time-line-item">
    <div class="col s12 l3 offset-l4">
      <div class="info info-left col s9">
        <h5 class="info-title ">PRODUCT</h5>
        <p class="info-paragraph ">
          NET QTY: 5130.00 <br>
          SALE PRICE: 2.938.258,065 BIRR <br>
          PRICE/KG: 888,671
        </p>
      </div>
      <div class="imgc col s3">
        <img class="item-img" src="/assets/img/timeline/bean.png" alt="server">
      </div>
      <div class="col s12">
        <div class="arrow-line"></div>
        <a class="marginT arrow"><i class="material-icons large arrow">arrow_downward</i></a>
      </div>
    </div>
  </div>
  <div class="row time-line-item">
    <div class="col s12 l3 offset-l4">
      <div class="imgc col s3">
        <img class="item-img" src="/assets/img/dashboard/transport.png" alt="server">
      </div>
      <div class="info info-right col s9">
        <h5 class="info-title">LOGISTICS</h5>
        <p class="info-paragraph ">
          QTY: 5130.00 <br>
          AMT: 378.958,00 BIRR
        </p>
      </div>
      <div class="col s12">
        <div class="arrow-line"></div>
        <a class="marginT arrow"><i class="material-icons large arrow">arrow_downward</i></a>
      </div>
    </div>
  </div>
  <div class="row col s12 time-line-item">
    <div class="col s12 l3 offset-l4">
      <div class="info info-left col s9">
        <h5 class="info-title ">ROASTERS</h5>
        <p class="info-paragraph ">
          QTY: 5130.00 <br>
          AMT: 378.958,00 BIRR
        </p>
      </div>
      <div class="imgc col s3">
        <img class="item-img" src="/assets/img/dashboard/roasting.png" alt="server">
      </div>
      <div class="col s12">
        <div class="arrow-line"></div>
        <a class="marginT arrow"><i class="material-icons large arrow">arrow_downward</i></a>
      </div>
    </div>
  </div>
  <div class="row time-line-item">
    <div class="col s12 l3 offset-l4">
      <div class="imgc col s3">
        <img class="item-img" src="/assets/img/dashboard/transport.png" alt="server">
      </div>
      <div class="info info-right col s9">
        <h5 class="info-title">LOGISTICS</h5>
        <p class="info-paragraph ">
          QTY: 5130.00 <br>
          AMT: 378.958,00 BIRR
        </p>
      </div>
      <div class="col s12">
        <div class="arrow-line"></div>
        <a class="marginT arrow"><i class="material-icons large arrow">arrow_downward</i></a>
      </div>
    </div>
  </div>
  <div class="row col s12 time-line-item">
    <div class="col s12 l3 offset-l4">
      <div class="info info-left col s9">
        <h5 class="info-title ">HULLING</h5>
        <p class="info-paragraph ">
          QTY: 6136.00 <br>
          AMT: 0,00 BIRR
        </p>
      </div>
      <div class="imgc col s3">
        <img class="item-img" src="/assets/img/timeline/wheel.png" alt="server">
      </div>
      <div class="col s12">
        <div class="arrow-line"></div>
        <a class="marginT arrow"><i class="material-icons large arrow">arrow_downward</i></a>
      </div>
    </div>
  </div>
  <div class="row time-line-item">
    <div class="col s12 l3 offset-l4">
      <div class="imgc col s3">
        <img class="item-img" src="/assets/img/dashboard/transport.png" alt="server">
      </div>
      <div class="info info-right col s9">
        <h5 class="info-title">LOGISTICS</h5>
        <p class="info-paragraph ">
          QTY: 5130.00 <br>
          AMT: 378.958,00 BIRR
        </p>
      </div>
      <div class="col s12">
        <div class="arrow-line"></div>
        <a class="marginT arrow"><i class="material-icons large arrow">arrow_downward</i></a>
      </div>
    </div>
  </div>
  <div class="row col s12 time-line-item">
    <div class="col s12 l3 offset-l4">
      <div class="info info-left col s9">
        <h5 class="info-title ">GRADING</h5>
        <p class="info-paragraph ">
          QTY: 7670,00 KGS <br>
          AMT: 248.255,00 BIRR
        </p>
      </div>
      <div class="imgc col s3">
        <img class="item-img" src="/assets/img/timeline/spyglass.png" alt="server">
      </div>
      <div class="col s12">
        <div class="arrow-line"></div>
        <a class="marginT arrow"><i class="material-icons large arrow">arrow_downward</i></a>
      </div>
    </div>
  </div>
  <div class="row time-line-item">
    <div class="col s12 l3 offset-l4">
      <div class="imgc col s3">
        <img class="item-img" src="/assets/img/timeline/washing.png" alt="server">
      </div>
      <div class="info info-right col s9">
        <h5 class="info-title">WETMILL</h5>
        <p class="info-paragraph ">
          QTY: 7670,00 <br>
          AMT: 0,00 BIRR
        </p>
      </div>
      <div class="col s12">
        <div class="arrow-line"></div>
        <a class="marginT arrow"><i class="material-icons large arrow arrow-left">arrow_downward</i></a>
        <a class="marginT arrow"><i class="material-icons large arrow">arrow_downward</i></a>
        <a class="marginT arrow "><i class="material-icons large arrow arrow-right">arrow_downward</i></a>
      </div>
    </div>
  </div>
  <div class="row marginT">
    <div class="col s4 l1 offset-l4 time-line-items"> <hr>
      <img class="item-img" src="/assets/img/timeline/shop.png" alt="server">
      <h5 class="info-title">SUBMSSION</h5>
      <p class="info-paragraph info-left">
        QTY: 6052,00 kgs <br>
        AMT: 96.560,00 BRIRR
      </p>
    </div>
    <div class="col s4 l1 time-line-items"> <hr>
      <img class="item-img" src="/assets/img/timeline/shop.png" alt="server">
      <h5 class="info-title">SUBMSSION</h5>
      <p class="info-paragraph info-left">
        QTY: 14.121,00 kgs <br>
        AMT: 255.306,00 BRIRR
      </p>
    </div>
    <div class="col s4 l1 time-line-items"> <hr>
      <img class="item-img" src="/assets/img/timeline/shop.png" alt="server">
      <h5 class="info-title">SUBMSSION</h5>
      <p class="info-paragraph info-left">
        QTY: 20.173.00 kgs <br>
        AMT: 321.866,00 BRIRR
      </p>
    </div>
  </div>
</div>



<ul id="slide-out" class="sidenav">
  <div class="row time-line-status container">
    <div class="close-button col s1 offset-s11">
      <a><i class="material-icons sidenav-close">clear</i></a>
    </div>
    <div class="title center">
      <h3>{{this.url}}</h3>
      <h4>Timeline status</h4>
      <hr>
    </div>
    <div class="status row">
      <div class="col s6"><img class="server-status " src="/assets/img/timeline/bean.png" alt="server">
        <p>Product</p>
      </div>
      <div class="col s6"><img class="server-status" src="/assets/img/dashboard/transport.png" alt="server">
        <p>Logistics</p>
      </div>
    </div>
    <div class="status row">
      <div class="col s6"><img class="server-status" src="/assets/img/dashboard/roasting.png" alt="server">
        <p>Roasters</p>
      </div>
      <div class="col s6"><img class="server-status" src="/assets/img/timeline/wheel.png" alt="server">
        <p>Hulling</p>
      </div>
    </div>
    <div class="status row">
      <div class="col s6"><img class="server-status" src="/assets/img/timeline/spyglass.png" alt="server">
        <p>Grading</p>
      </div>
      <div class="col s6"><img class="server-status" src="/assets/img/timeline/washing.png" alt="server">
        <p>Wetmill</p>
      </div>
    </div>
  </div>
  <div class="status row">
    <div class="col s6 offset-s3"><img class="server-status" src="/assets/img/timeline/shop.png"
        alt="server">
      <p>Submission</p>
    </div>
  </div>
</ul>
