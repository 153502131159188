<div class="errorpage {{backgorund}}">
    <div class="img {{img}}">

    </div>
    <div class="textCard">
        <div>
            <span><b>The requested page does not exist or is unavailable right now.</b></span>
        </div>
        <div>
            <span><b>Return to home <a routerLink="/home">here</a></b></span>
        </div>
    </div>