<app-navbar></app-navbar>

<div class="container">
<h1 class="title"> About Us </h1>
<div class="text">
    <img class="image" src="https://get.foundation/assets/img/sites-templates/f6-template-news-mag.svg">
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, natus consectetur?
    Placeat cupiditate quaerat molestiae voluptatem ducimus, ea ad similique magnam
    facere atque officia neque rerum dicta perferendis libero error. Lorem, ipsum dolor
    sit amet consectetur adipisicing elit. Voluptatum, reprehenderit possimus magnam quidem 
    voluptate, molestiae perspiciatis, sit voluptates aut facilis ducimus dolore. Similique,
    ut molestias aliquam labore maxime dolores esse. Lorem ipsum dolor sit amet consectetur 
    adipisicing elit. Illum, natus consectetur?
    Placeat cupiditate quaerat molestiae voluptatem ducimus, ea ad similique magnam
    facere atque officia neque rerum dicta perferendis libero error. Lorem, ipsum dolor
    sit amet consectetur adipisicing elit. Voluptatum, reprehenderit possimus magnam quidem 
    voluptate, molestiae perspiciatis, sit voluptates aut facilis ducimus dolore. Similique,
    ut molestias aliquam labore maxime dolores esse.</p>
  </div>
</div>
