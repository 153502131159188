import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { AuthGuard } from "../../shared/guard/auth.guard";
import { ErrorPageComponent } from '../../components/error-page/error-page.component';
import { HomeComponent } from '../../components/home/home.component';
import { TimeLineComponent} from '../../components/time-line/time-line.component';
import { TimedOutComponent } from '../../components/timed-out/timed-out.component';
import { InformationComponent } from 'src/app/components/information/information.component';
import { AboutUsComponent } from '../../components/about-us/about-us.component';

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent},
  { path: 'sign-in/:target', component: SignInComponent},
  { path: 'sign-up', component: SignUpComponent},
  { path: 'dashboard', component: DashboardComponent  },
  { path: 'dashboard/:coffee', component: InformationComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  // { path: 'time-line/:coffee', component: TimeLineComponent, canActivate: [AuthGuard]},
  { path: 'time-line', component: TimeLineComponent},
  { path: 'timed-out', component: TimedOutComponent},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'home', component: HomeComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '**', component: ErrorPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
