import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as M from "../../../../node_modules/materialize-css";

@Component({
  selector: "app-time-line",
  templateUrl: "./time-line.component.html",
  styleUrls: ["./time-line.component.css"],
})
export class TimeLineComponent implements OnInit {
  //TODO api aanroepen en juiste data tonen + server status
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((result) => {
      this.url = result["coffee"];
    });
  }

  public url;

  ngOnInit(): void {
    setTimeout(function () {
      var elems = document.querySelectorAll(".sidenav");
      var instances = M.Sidenav.init(elems);
    }, 0);
  }
}
