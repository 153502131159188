import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-lifecycle",
  templateUrl: "./lifecycle.component.html",
  styleUrls: ["./lifecycle.component.scss"],
})
export class LifecycleComponent implements OnInit {
  //declaring variables
  FarmerDetails = "";
  TotalQuantity;
  TotalPer250Gm;
  TotalAmount;
  CostPer250Gm;
  Location;
  checked;
  messages;
  processesFirst;
  url;
  target;
  text = "";
  title = "";
  rotate = 0;

  //declaring the object
  processes = [
    {
      title: "Harvest",
      position: 0,
      id: "harvest",
      api: "Harvest",
      class: "active",
      message: "",
    },
    {
      title: "Action",
      position: 1,
      id: "action",
      api: "",
      class: "",
      message: "",
    },
    {
      title: "Sales",
      position: 2,
      id: "sales",
      api: "Sales",
      class: "",
      message: "",
    },
    {
      title: "Transport",
      position: 3,
      id: "transport",
      api: "Logistics",
      class: "",
      message: "",
    },
    {
      title: "Roasting",
      position: 4,
      id: "roasting",
      api: "Roasting",
      class: "",
      message: "",
    },
    {
      title: "Training",
      position: 5,
      id: "training",
      api: "",
      class: "",
      message: "",
    },
    {
      title: "Grading",
      position: 6,
      id: "grading",
      api: "Grading",
      class: "",
      message: "",
    },
    {
      title: "Washing",
      position: 7,
      id: "washing",
      api: "Washing",
      class: "",
      message: "",
    },
  ];

  constructor(public router: Router, public api: ApiService) {
    this.url = this.router.url;
  }

  //to implement the HeadlessCMS
  headlessCMS() {
    this.api.getHeadlessCMS().subscribe((res) => {
      //subscribing to the headless cms
      this.messages = res; //putting the result into a variable

      Object.keys(this.messages).forEach((key) => {
        //looping through the object
        Object.keys(this.processes).forEach((key2) => {
          //looping again
          if (this.messages[key].title.rendered == this.processes[key2].title) {
            //finding matches between the 2 loops

            this.processes[key2].message = this.messages[key].content.rendered; //putting the message in the process object
          }
        });
      });
    });
  }

  //ngAfterViewInit for loading this code after the website loads
  ngAfterViewInit() {
    //this.headlessCMS()    //calling the function to the headlessCMS
    let elementRotate = 360 / this.processes.length; //calculating how much degrees every object should rotate

    //actually rotating the thing
    this.processes.forEach((value, index) => {
      document.getElementById(value.title).style.transform =
        "rotate(" + elementRotate * -index + "deg)";
      document.getElementById(value.id + value.position).style.width =
        70 / this.processes.length + "em";
    });
  }
  //after the view has been checked
  ngAfterViewChecked() {
    //making sure the farmers are displayed
    if (this.processes[0].title == "Harvest") {
      document.getElementById("harvest").click();
    }
  }

  //the click event
  click1(event) {
    //breadcrumb
    document.getElementById(this.processes[0].id).style.backgroundColor =
      "#949494";

    //gets the clicked target here
    const target = event.target;
    //foreach to loop through the object
    this.processes.forEach((value, index) => {
      //if the id of the clicked object is the same as the one where the foreach is
      if (target.getAttribute("id") == value.id) {
        //to shift through the array to get the selected object first
        for (let u = 0; u !== index; u++) {
          this.processesFirst = this.processes.shift();
          this.processes.push(this.processesFirst);
        }
        //rotate the whole cycle
        this.rotate = this.rotate + (360 / this.processes.length) * index;
        document.getElementById("wrapper").style.transform =
          "rotate(" + this.rotate + "deg)";
        document.getElementById("innerCircle").style.transform =
          "rotate(" + -this.rotate + "deg)";
        //reverting the breadcrumb
        document.getElementById(this.processes[0].id).style.backgroundColor =
          "#d34c4c";
        //changing the innercircle picture
        document.getElementById("innerCircle").style.backgroundImage =
          'url("../../assets/img/lifecycle/' + this.processes[0].id + ".png";
        //if there is no api, clear the text and details so i twon't show up on the html
        if (this.processes[0].api == "") {
          this.text = "";
          this.FarmerDetails = "";
        } else {
          //putting the text to true and filling in all the specifics
          this.text = "true";
          this.CostPer250Gm =
            Math.round(
              JSON.parse(sessionStorage.getItem(this.processes[0].api))
                .CostPer250Gm * 100
            ) / 100;
          this.Location = JSON.parse(
            sessionStorage.getItem(this.processes[0].api)
          ).Location;
          this.TotalAmount =
            Math.round(
              JSON.parse(sessionStorage.getItem(this.processes[0].api))
                .TotalAmount * 100
            ) / 100;
          this.TotalPer250Gm =
            Math.round(
              JSON.parse(sessionStorage.getItem(this.processes[0].api))
                .TotalPer250Gm * 100
            ) / 100;
          this.TotalQuantity = JSON.parse(
            sessionStorage.getItem(this.processes[0].api)
          ).TotalQuantity;
          this.FarmerDetails = JSON.parse(
            sessionStorage.getItem(this.processes[0].api)
          ).FarmerDetails;
        }
        document.getElementById("messages").innerHTML = "";
        document.getElementById("messages").innerHTML =
          this.processes[0].message;
      }
    });
  }
  ngOnInit() {}
}
