<!-- TODO dashboard sign in -->

<div class="row">
  <!-- hero -->
  <div class="col s4 m4 l4 hero hide-on-small-only {{hero}}"></div>
  <div class="col s12 m8 l8 login">
    <div class="row">
      <div class="col s12 m12 l10 xl8 offset-l1 offset-xl2 login-form">
        <!-- card -->
        <div class="card z-depth-5">
          <!-- card content -->
          <div class="card-content">
            <!-- logo card -->
            <img src="/assets/img/Logo_FC_TECH.png " class="logo" alt="">
            <!-- card title -->
            <span class="card-title center-align">Log in</span>
            <!-- error message -->
            <p *ngIf="errorMsg" class="errorMsg center-align">*{{errorMsg}}*</p>
            <div class="row">
              <!-- email input -->
              <div class="input-field col s10 l8 offset-s1 offset-l2">
                <input id="email" type="email" #email required>
                <label for="email">Email</label>
              </div>
            </div>
            <!-- password input -->
            <div class="row">
              <div class="input-field col s10 l8 offset-s1 offset-l2">
                <input id="password" type="password" #userPassword required>
                <label for="password">Password</label>
              </div>
            </div>
            <!-- sign in button -->
            <div class="row">
              <a *ngIf="!loadercircle" class="waves-effect waves-light btn col s4 offset-s4" (click)="resetError()"
                (click)="signInMethod(email.value, userPassword.value)">Log in</a>
            <p class="col s12 center home_link"><i class="material-icons back_icon">arrow_back</i>terug naar <a routerLink="/home" >home</a> </p>

              <!-- loader -->
              <div *ngIf="loadercircle" class="progress col s4 offset-s4">
                <div class="indeterminate"></div>
              </div>
              <!-- redirect to dashboard -->
              <p *ngIf="loadercircle" class="col s4 offset-s4">Taking to long to log in? <a
                  routerLink="/dashboard">click here</a></p>
            </div>
            <div class="row msg">

              <!-- forgot password link -->
              <p class="pwForgot" routerLink="/forgot-password">Forgot password?</p>
              <!-- sign up link -->
              <p>Don't have a account? <a routerLink="/sign-up">sign up</a></p>
            </div>
          </div>
        </div>
        <p class="center-align">OR</p>
        <!-- google sign in -->
        <button type="button" class="btn googleBtn col s6 l4 offset-s3 offset-l4" (click)="authService.GoogleAuth()"
          (click)="loader()">
          <i class="fab fa-google-plus-g"></i>
          Log in with Google
        </button>
      </div>
    </div>
  </div>
</div>
