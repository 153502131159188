import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";
import * as M from "../../../../node_modules/materialize-css";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  @Input() parentUrl: string;
  loggedin = false;

  constructor(public router: Router, public authService: AuthService) {
    this.parentUrl = this.router.url;
    this.loggedin = this.authService.isLoggedIn;
  }

  ngOnInit(): void {
    setTimeout(function () {
      var elems = document.querySelectorAll(".dropdown-trigger");
      var instances = M.Dropdown.init(elems);
    }, 0);

    setTimeout(function () {
      var elem = document.querySelector(".sidenav");
      var instance = M.Sidenav.init(elem);
    }, 0);
  }

  signOut() {
    this.loggedin = false;
    this.authService.SignOut();
  }
}
