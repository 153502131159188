<app-navbar></app-navbar>
<div class="row">
    <div class="side-nav-button">
        <a data-target="slide-out" class="sidenav-trigger waves-light btn halfbtn">Status</a>
        <!-- <h4 class="center">{{this.url}}</h4>
        <h5 class="center">{{this.pbatId}}</h5> -->
    </div>

    <div *ngIf="!this.showCycle" class="loaderDiv center-align">
        <div class="fa-3x">
            <i class="fas fa-circle-notch fa-spin"></i>
        </div>
    </div>
    <div *ngIf="this.showCycle">
        <div class="desktop hide-on-med-and-down">
            <app-lifecycle></app-lifecycle>
        </div>
        <div class="mobile hide-on-large-only">
            <app-lifecycle-mobile></app-lifecycle-mobile>
        </div>
    </div>
</div>

<!-- TODO timer bij status -->
<ul id="slide-out" class="sidenav">
    <div class="row time-line-status container">
        <div class="close-button col s1 offset-s11">
            <a><i class="material-icons sidenav-close">clear</i></a>
        </div>
        <div class="status-title center">
            <!-- <h4>{{this.url}}</h4>
            <h5>{{this.pbatId}}</h5> -->
            <h5>Server status</h5>
            <hr class="line">
        </div>
        <div class="status row">
            <div class="col s6">
                <img [ngClass]='{"statusGreen" : apiStatus["Harvest"], "statusOrange" : apiStatus["Harvest"] == "pending"}' class="server-status" src="/assets/img/dashboard/farmer'.png" alt="server">
                <p>Farmers</p>
            </div>
            <div class="col s6">
                <img [ngClass]='{"statusGreen" : apiStatus["Grading"], "statusOrange" : apiStatus["Grading"] == "pending"}' class="server-status" src="/assets/img/dashboard/grading2.png" alt="server">
                <p>Grading</p>
            </div>
        </div>
        <div class="status row">
            <div class="col s6">
                <img [ngClass]='{"statusGreen" : apiStatus["Washing"], "statusOrange" : apiStatus["Washing"] == "pending"}' class="server-status statusRed" src="/assets/img/timeline/washing.png" alt="server">
                <p></p>
            </div>
            <div class="col s6">
                <img [ngClass]='{"statusGreen" : apiStatus["Logistics"], "statusOrange" : apiStatus["Logistics"] == "pending"}' class="server-status statusRed" src="/assets/img/dashboard/transport.png" alt="server">
                <p>Transport</p>
            </div>
        </div>
        <div class="status row">
            <div class="col s6">
                <img [ngClass]='{"statusGreen" : apiStatus["Roasting"], "statusOrange" : apiStatus["Roasting"] == "pending"}' class="server-status statusRed" src="/assets/img/dashboard/roasting.png" alt="server">
                <p>Roasting</p>
            </div>
            <div class="col s6">
                <img [ngClass]='{"statusGreen" : apiStatus["Sales"], "statusOrange" : apiStatus["Sales"] == "pending"}' class="server-status statusRed" src="/assets/img/dashboard/sales2.png" alt="server">
                <p>Sales</p>
            </div>
        </div>
        <b><p class="center" id="time"></p></b>
    </div>
</ul>