<div class="navbar-fixed">
    <nav class="no-shadows ">
        <div class="nav-wrapper ">
            <div class="container">
                <a class="brand-logo" routerLink="/home"><img class="nav_logo" src="/assets/img/logo/logo.png" alt="logo"></a>
                <a data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></a>
                <ul class="right hide-on-med-and-down">
                    <!-- <li><a routerLink="/home" [routerLinkActive]="['is-active']">Home</a></li> -->
                    <li><a routerLink="/dashboard/valuedistributiondiagram" [routerLinkActive]="['is-active']">Dashboard</a></li>
                    <!-- <li><a class="dropdown-trigger" data-target="dropdown1">Timeline<i class="material-icons right">arrow_drop_down</i></a></li> -->
                    <!-- <li><a routerLink="/about-us" [routerLinkActive]="['is-active']">About us</a></li> -->
                </ul>
            </div>
        </div>
        <div class="line"></div>
    </nav>
</div>

<ul class="sidenav sidenav-close " id="mobile-demo">
    <li><a routerLink="/home" [routerLinkActive]="['is-active']">Home</a></li>
    <li><a routerLink="/dashboard/valuedistributiondiagram" [routerLinkActive]="['is-active']">Dashboard</a></li>
    <!-- <li><a *ngIf="loggedin" routerLink="/dashboard" [routerLinkActive]="['is-active']">Dashboard</a></li> -->
    <!-- <li><a routerLink="/time-line" [routerLinkActive]="['is-active']">Time line</a></li> -->
    <!-- <li><a>About us</a></li> -->
</ul>