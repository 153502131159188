import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  hero;
  errorMsg;
  constructor(public authService: AuthService) {
    this.hero = this.authService.getHeroImg();
  }

  ngOnInit() {}

  //get error message
  getError() {
    this.errorMsg = this.authService.getError();
  }
}
