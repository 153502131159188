<div class="row col s12">


    <ul class="collapsible">
        <li>
            <div class="collapsible-header"><i class="material-icons"><img src="/assets/img/dashboard/farmer'.png"  class="icon"></i>Harvest</div>
            <div class="collapsible-body">
                <span>
                <p>
                  <img class="logoimg" src="/assets/img/lifecycle/Kilo.png">{{this.data["Harvest"]["Extra"].TotalQuantity | number}} kg
                </p>
                <p>
                  <img class="logoimg" src="/assets/img/lifecycle/location.png">{{this.data["Harvest"]["Extra"].Location}}
                </p>
                <p>
                  <img class="logoimg" src="/assets/img/lifecycle/price.png">{{this.data["Harvest"]["Extra"].TotalAmount | number}} BIRR
                </p>
              </span>
                <div>
                    <p>Farmers:</p>
                    <ul class="browser-default" style="list-style-type:disc">
                        <li *ngFor="let farmer of this.data['Harvest']['Extra'].FarmerDetails">{{farmer.farmerName}}</li>
                    </ul>
                </div>
                <br>
                <span>{{this.processes[0].message}}</span>
            </div>
        </li>
        <li>
            <div class="collapsible-header"><i class="material-icons"><img src="/assets/img/dashboard/washing.png" class="icon"></i>Washing</div>
            <div class="collapsible-body">
                <span>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/Kilo.png">{{this.data["Washing"]["Extra"].TotalQuantity | number}} kg
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/location.png">{{this.data["Washing"]["Extra"].Location}}
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/price.png">{{this.data["Washing"]["Extra"].TotalAmount | number}} BIRR
              </p>
            </span>
                <span>{{this.processes[7].message}}</span>
            </div>
        </li>
        <li>
            <div class="collapsible-header"><i class="material-icons"><img src="/assets/img/dashboard/grading2.png" class="icon"></i>Grading</div>
            <div class="collapsible-body">
                <span>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/Kilo.png">{{this.data["Grading"]["Extra"].TotalQuantity | number}} kg
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/location.png">{{this.data["Grading"]["Extra"].Location}}
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/price.png">{{this.data["Grading"]["Extra"].TotalAmount | number}} BIRR
              </p>
            </span>
                <span>{{this.processes[6].message}}</span>
            </div>
        </li>
        <li>
            <div class="collapsible-header"><i class="material-icons"><img src="/assets/img/lifecycle/training.png"class="icon"></i>Training</div>
            <div class="collapsible-body">
                <span>{{this.processes[5].message}}</span>
            </div>
        </li>
        <li>
            <div class="collapsible-header"><i class="material-icons"><img src="/assets/img/dashboard/roasting.png" alt="" class="icon"></i>Roasting</div>
            <div class="collapsible-body">
                <span>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/Kilo.png">{{this.data["Roasting"]["Extra"].TotalQuantity | number}} kg
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/location.png">{{this.data["Roasting"]["Extra"].Location}}
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/price.png">{{this.data["Roasting"]["Extra"].TotalAmount | number}} BIRR
              </p>
            </span>
                <span>{{this.processes[4].message}}</span>
            </div>
        </li>
        <li>
            <div class="collapsible-header"><i class="material-icons"><img src="/assets/img/dashboard/transport.png" class="icon"></i>Transport</div>
            <div class="collapsible-body">
                <span>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/Kilo.png">{{this.data["Logistics"]["Extra"].TotalQuantity | number}} kg
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/location.png">{{this.data["Logistics"]["Extra"].Location}}
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/price.png">{{this.data["Logistics"]["Extra"].TotalAmount | number}} BIRR
              </p>
            </span>
                <span>{{this.processes[3].message}}</span>
            </div>
        </li>
        <li>
            <div class="collapsible-header"><i class="material-icons"><img src="/assets/img/dashboard/sales2.png" class="icon"></i>Sales</div>
            <div class="collapsible-body">
                <span>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/Kilo.png">{{this.data["Sales"]["Extra"].TotalQuantity | number}} kg
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/location.png">{{this.data["Sales"]["Extra"].Location}}
              </p>
              <p>
                <img class="logoimg" src="/assets/img/lifecycle/price.png">{{this.data["Sales"]["Extra"].TotalAmount | number}} BIRR
              </p>
            </span>
                <span>{{this.processes[2].message}}</span>
            </div>
        </li>
        <li>
            <div class="collapsible-header"><i class="material-icons"><img src="/assets/img/lifecycle/action.png" class="icon"></i>Action</div>
            <div class="collapsible-body">
                <span>{{this.processes[1].message}}</span>
            </div>
        </li>
    </ul>
</div>