<div class="divbody container row">

  <div class="wrapper col s4 offset-s2" id="wrapper">

    <div class="elements" *ngFor="let process of processes" id="{{process.title}}" (click)='click1($event)' >
      <div class="oneCycle" id="{{process.id}}{{process.position}}"><img
          src="/assets/img/lifecycle/oneCycle.png"></div>
      <span id="{{process.id}}">{{process.title}}</span>
    </div>
    <div class="middlecircle" id="innerCircle"></div>
  </div>



  <div class="wrapperline hide-on-med-and-down col l4 offset-l4">
    <div class="topline">

    </div>
    <div class="bottomline">

    </div>
  </div>

  <div class="content col s6">
    <h2 class="title">{{this.processes[0].title}}</h2>

    <div *ngIf="this.text" class="text row">
      <div class="col s6">
        <p> <b> Total {{this.title}}: </b></p>
        <img src="/assets/img/lifecycle/Kilo.png"> <span> {{this.TotalQuantity}} Kg</span> <br>
        <img src="/assets/img/lifecycle/location.png"> <span> {{this.Location}}</span><br>
        <img src="/assets/img/lifecycle/price.png"> <span> €{{this.TotalAmount}}</span><br>
      </div>
      <div class="col s6">
        <p> <b> Cost per 250g coffee: </b></p>
        {{this.processes[0].title}}: {{this.CostPer250Gm}} <br>
        Total: {{this.TotalPer250Gm}}
      </div>

    </div>

    <div class="farmerNames" *ngIf="this.FarmerDetails" >
      <p> <b>Farmers: </b></p>
      <ul class="browser-default" style="list-style-type:disc">
        <li *ngFor="let farmers of FarmerDetails">
          {{farmers.farmerName}}
        </li>
      </ul>
    </div>
    <div id="messages">

    </div>
  </div>
</div>
