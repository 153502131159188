import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.css"],
})
export class ErrorPageComponent implements OnInit {
  img;
  backgorund;
  url;
  constructor(private router: Router) {
    this.url = this.router.url;
  }

  ngOnInit(): void {
    if (this.url == "/401") {
      this.img = "lock";
      this.backgorund = "blueError";
    } else {
      this.getBackground();
    }
  }

  getBackground() {
    let img = ["plug", "bean", "farmer", "tree", "bos"];
    this.img = img[Math.floor(Math.random() * img.length)];
    if (this.img == "plug" || this.img == "bean") {
      this.backgorund = "pinkError";
    } else {
      this.backgorund = "blueError";
    }
  }
}
