import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
})
export class SignUpComponent implements OnInit {
  //random hero img
  hero;
  //error message
  errorMsg;
  constructor(public authService: AuthService) {
    //get random hero img
    this.hero = this.authService.getHeroImg();
  }

  ngOnInit() {}
  //get error
  getError() {
    this.errorMsg = this.authService.getError();
  }
}
