<div class="row">
  <!-- hero -->
  <div class="col s4 m4 l4 hero hide-on-small-only {{hero}}"></div>
  <div class="col s12 m8 l8 login">
    <div class="row">
      <div class="col s12 m12 l10 xl8 offset-l1 offset-xl2 login-form">
        <!-- card -->
        <div class="card z-depth-5">
          <div class="card-content">
            <!-- logo card -->
            <img src="/assets/img/Logo_FC_TECH.png " class="logo" alt="">
            <!-- card title -->
            <span class="card-title center-align">Reset Password</span>
            <div class="row">
              <p class="pwdReset center-align">Please enter your email address to request a password reset.</p>
              <!-- card error message -->
              <p *ngIf="errorMsg" class="errorMsg center-align">*{{errorMsg}}*</p>
              <!-- email input -->
              <div class="input-field col s10 l8 offset-s1 offset-l2">
                <input id="email" type="email" #passwordResetEmail required>
                <label for="email">Email</label>
              </div>
            </div>
            <!-- send reset email -->
            <div class="row">
              <a class="waves-effect waves-light btn col s4 offset-s4"
                (click)="authService.ForgotPassword(passwordResetEmail.value)" (click)="getError()">Reset Password</a>
            <p class="col s12 center home_link"><i class="material-icons back_icon">arrow_back</i>terug naar <a routerLink="/home" >home</a> </p>

            </div>
            <div>
              <!-- go back to login -->
              <p>Go back to <a routerLink="/sign-in">Log in</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
