import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/service/api.service";
import * as M from "../../../../node_modules/materialize-css";

@Component({
  selector: "app-information",
  templateUrl: "./information.component.html",
  styleUrls: ["./information.component.css"],
})
export class InformationComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService
  ) {
    this.token = sessionStorage.getItem("token");
    this.url = this.route.snapshot.params["coffee"];
    this.route.params.subscribe((result) => {
      this.url = result["coffee"];
    });
  }
  public apiStatus = [
    "Harvest",
    "Grading",
    "Logistics",
    "Roasting",
    "Sales",
    "Washing",
  ];
  public availableCoffee = [
    "kenyaTriple",
    "kenyaSingle",
    "ethopia-double",
    "single-2018",
    "ethopia-double-TL",
    "valuedistributiondiagram",
  ];
  public pbatId;
  public url;
  public token;
  public coffeeType: string;

  ngOnInit(): void {
    this.apiStatus["Harvest"] = false;
    this.apiStatus["Grading"] = false;
    this.apiStatus["Logistics"] = false;
    this.apiStatus["Roasting"] = false;
    this.apiStatus["Sales"] = false;
    this.apiStatus["Washing"] = false;

    var timer = 60 * 1,
      display = document.querySelector("#time");
    this.startTimer(timer, display);
    setInterval(() => {
      this.apiStatus["Harvest"] = "pending";
      this.apiStatus["Grading"] = "pending";
      this.apiStatus["Logistics"] = "pending";
      this.apiStatus["Roasting"] = "pending";
      this.apiStatus["Sales"] = "pending";
      this.apiStatus["Washing"] = "pending";
      setTimeout(() => {
        this.apiStatus["Harvest"] = true;
        this.apiStatus["Grading"] = true;
        this.apiStatus["Logistics"] = true;
        this.apiStatus["Roasting"] = true;
        this.apiStatus["Sales"] = true;
        this.apiStatus["Washing"] = true;
      }, 5000);
    }, 61 * 1000);

    setTimeout(function () {
      var elems = document.querySelectorAll(".sidenav");
      var instances = M.Sidenav.init(elems);
    }, 0);

    switch (this.url) {
      case "kenyaTriple":
        this.pbatId = "PBAT973343";
        break;
      case "kenyaSingle":
        this.pbatId = "PBAT237846";
        break;
      case "ethopia-double":
        this.pbatId = "PBAT297395";
        break;
      case "single-2018":
        this.pbatId = "PBAT100445";
        break;
      case "ethopia-double-TL":
        this.pbatId = "PBAT709019";
        break;
      case "valuedistributiondiagram":
        this.pbatId = "randompbat";
        break;
    }

    this.token = "test";

    this.coffeeType = this.url;
    if (this.availableCoffee.includes(this.coffeeType)) {
      this.setAPIJSON(this.token, this.coffeeType);
    } else {
      this.router.navigate(["404"]);
    }
  }

  /**
   * Set the data for API (${type} was used but all coffee were the same)
   * @param token
   * @param type
   */
  setAPIJSON(token, type) {
    let completeJson = `{"token": "test","coffeeType":"kenyaTriple"}`;
    this.getApis(completeJson);
  }

  //All path url's for api
  public urls: string[] = [
    "get-data", //request all api's in one so it saves api requests
  ];

  showCycle: boolean = false;
  //Calls api for each string in urls{}
  private getApis(jsonbody) {
    this.api.getData(jsonbody).subscribe((res) => {
      console.log(res);
      sessionStorage.setItem("allData", `${JSON.stringify(res)}`);
      Object.keys(res).forEach((key) => {
        sessionStorage.setItem(`${key}`, `${JSON.stringify(res[key].Extra)}`);
        this.apiStatus[key] = res[key].Status;
      });
      this.showCycle = true;
    });
  }

  public startTimer(duration, display) {
    var start = Date.now(),
      diff,
      minutes,
      seconds;
    function timer() {
      // get the number of seconds that have elapsed since
      // startTimer() was called
      diff = duration - (((Date.now() - start) / 1000) | 0);

      // does the same job as parseInt truncates the float
      minutes = (diff / 60) | 0;
      seconds = diff % 60 | 0;

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = seconds + " sec";

      if (diff <= 0) {
        // add one second so that the count down starts at the full duration
        // example 05:00 not 04:59

        start = Date.now() + 1000;
      }
    }
    // we don't want to wait a full second before the timer starts
    timer();
    setInterval(timer, 1000);
  }

  public setStatus() {
    this.apiStatus["Harvest"] = "pending";
    this.apiStatus["Grading"] = "pending";
    this.apiStatus["Logistics"] = "pending";
    this.apiStatus["Roasting"] = "pending";
    this.apiStatus["Sales"] = "pending";
    this.apiStatus["Washing"] = "pending";
    console.log(this.apiStatus);
  }
}
