<div id="modal" class="modal modal-fixed-footer center">
    <div class="modal-content">
        <h4>Timed out</h4>
        <p>
            You have been logged out due to inactivity <br>
            You can log in or go back to the home screen
        </p>
        <div class="row buttons">
            <a routerLink="/sign-in" class="modal-close waves-effect waves-light btn col s3 offset-s2">Sign in</a>
            <a routerLink="/home" class="modal-close waves-effect waves-light btn col s3 offset-s2">home</a>
        </div>
    </div>

</div>