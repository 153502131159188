import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(public http: HttpClient) {}

  getData(jsonBody) {
    let body = JSON.parse(jsonBody);
    return this.http.post(
      `https://us-central1-test-46798.cloudfunctions.net/app/get-data`,
      body
    );
  }

  getHeadlessCMS() {
    return this.http.get(
      "http://fairchain.digitalnation.nl/wp-json/wp/v2/posts"
    );
  }
}
