import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import * as firebase from "firebase";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public router: Router,
    public api: ApiService
  ) {
    this.url = this.router.url;
  }

  public url;

  ngOnInit() {
    // this.setIdToken();
  }

  setIdToken() {
    firebase.auth().onAuthStateChanged(function (user) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          let refinedToken = `"idToken": "${idToken}"`;

          sessionStorage.setItem("token", refinedToken);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }
}
