<app-navbar></app-navbar>

<!-- <div class="row container">
    <div class="col s12  xl6">
        <div class="map">
            <img src="/assets/img/map.png">
        </div>
    </div>
    <div class="col s12 xl6">
        <div class="section">
            <h1 class="section_title">LOREM IPSUM</h1>
          <p class="section_paragraph">    
           Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
           At quas obcaecati officia? Dolorem doloribus quam est atque necessitatibus temporibus quaerat 
           adipisci voluptate, itaque, praesentium veniam, alias inventore eligendi quae aspernatur.
           Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
           At quas obcaecati officia? Dolorem doloribus quam est atque necessitatibus temporibus quaerat 
           adipisci voluptate, itaque, praesentium veniam, alias inventore eligendi quae aspernatur.
           Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
           At quas obcaecati officia? Dolorem doloribus quam est atque necessitatibus temporibus quaerat 
           adipisci voluptate, itaque, praesentium veniam, alias inventore eligendi quae aspernatur.
           Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
           At quas obcaecati officia? Dolorem doloribus quam est atque necessitatibus temporibus quaerat
           At quas obcaecati officia? Dolorem doloribus quam est atque necessitatibus temporibus quaerat      
          </p>
          </div>
    </div>
</div> -->