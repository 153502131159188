import { Injectable, NgZone } from "@angular/core";
import { User } from "../services/user";
import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { Location } from "@angular/common";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import * as firebase from "firebase";
import * as store from "store";

const MINUTES_UNITL_AUTO_LOGOUT = 15; // in Minutes
const CHECK_INTERVALL = 1000; // in ms
const STORE_KEY = "lastAction";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  userData: any; // Save logged in user data
  error; //error message
  target = "";
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public route: ActivatedRoute,
    public _location: Location,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        sessionStorage.setItem("user", JSON.stringify(this.userData));
        JSON.parse(sessionStorage.getItem("user"));
        this.ngZone.run(() => {
          if (this.target == "sign-in") {
            this.router.navigate(["home"]);
            this.target = "";
          } else if (this.target == null) {
            this.router.navigate(["home"]);
            this.target = "";
          } else {
            this.router.navigate([`${this.target}`]);
            this.target = "";
          }
        });
      } else {
        sessionStorage.setItem("user", null);
        JSON.parse(sessionStorage.getItem("user"));
      }
    });
    // this.check();
    // this.initListener();
    // this.initInterval();
  }
  // Sign in with email/password
  SignIn(email, password, target = null) {
    this.target = target;
    email = email.trim();
    password = password.trim();
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user);
      })
      .catch((error) => {
        this.error = error.message;
      });
  }

  // Sign up with email/password
  SignUp(email, password) {
    email = email.trim();
    password = password.trim();
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        // this.SendVerificationMail();
        this.SignIn(email, password);

        // this.SetUserData(result.user);
      })
      .catch((error) => {
        // window.alert(error.message)
        // this.setError(error.message);
        this.error = error.message;
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
      this.router.navigate(["verify-email-address"]);
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    passwordResetEmail = passwordResetEmail.trim();
    return this.afAuth.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert("Password reset email sent, check your inbox.");
      })
      .catch((error) => {
        // window.alert(error)
        // this.setError(error.message);
        this.error = error.message;
      });
  }

  // Returns true when user is loged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem("user"));
    return user !== null ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then((result) => {
        this.SetUserData(result.user);
      })
      .catch((error) => {
        // window.alert(error)
        this.error = error.message;
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };

    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign out
  //FIXME clear session storage op logout
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      sessionStorage.clear();
    });
  }

  // return error and clears it from auth service
  getError() {
    let error2;
    error2 = this.error;
    this.error = "";
    return error2;
  }
  //get random hero img
  getHeroImg() {
    let heros = ["hero1", "hero2", "hero3"];
    let hero = heros[Math.floor(Math.random() * heros.length)];
    return hero;
  }

  get lastAction() {
    return parseInt(store.get(STORE_KEY));
  }
  set lastAction(value) {
    store.set(STORE_KEY, value);
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener("click", () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    });
  }

  reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout && this.isLoggedIn) {
        this.SignOut();
        console.log("loged out");

        this.router.navigate(["timed-out"]);
      }
    });
  }
}
